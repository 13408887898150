import { StatusCell } from "components/things"

export const Stats = ({ stats }) => do {
  const [ on, off, out, err ] = stats;

  display: flex;
  flexDirection: right;
  flexWrap: wrap
  marginBottom: -42

  NumberStat(label `on policy`, n = on)
  NumberStat(label `inactive`,  n = off, color `orange`)
  NumberStat(label `off site`,  n = out, color `#CCC`)
  NumberStat(label `not found`, n = err, color = false)
}

const NumberStat = ({ color, n: number, label }) => do {
  number: {
    font: 16, 400;
    fontFamily: monospace;
    marginR: 5
  }
  status: {
    font: 14, 500;
    color: 0x777;
  }

  if(number > 0)
  StatusCell, do {
    + color;
    !span.number > number;
    !span.status ` ${label}`;
  }
}