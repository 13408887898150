import { RouteContainer } from "components/window";
import { Router } from '@reach/router';

import { SpecificPosition } from "./details"
import { AppSearch } from "./search"
import { Positions } from "./positions"

export const Policies = () => do {
  Router, do {
    Positions(path `/`);
    SpecificPosition(path `:position`);
    AppSearch(path `:position/apps`)
    OhNo({ default: true })
  }
}

const OhNo = () => do {
  RouteContainer, do {
    "o no"
  }
}