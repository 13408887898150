import { navigate } from "@reach/router"
import { LogoAnimated } from "components/logo"
import { didLoadUser } from 'lib/firebase';
import { useStates } from "use-stateful"

export const Header = () => do {
  const $ = useStates((onUnmount) => {
    const unsub = didLoadUser(
      user => { $.user = user }
    );
    
    onUnmount(unsub);
    return { user: null }
  })

  fixed: fill-top;
  background: 0xf9fafb;
  zIndex: 4

  container, do {
    marginH: 20;
    height: 60;
    paddingT: 5

    margins, do {
      borderBottom: 0x1, 1;
      margin: auto;
      maxWidth: 1280
      height: 60;
      display: flex;
      justifyContent: space-between;
      flexDirection: row;
      alignItems: center;
      paddingH: 10

      left, do {
        flexAlign: center, right;
        WebkitUserSelect: none;
        cursor: pointer;
  
        onClick = () => navigate($.user ? "/admin/people" : "/login");
  
        LogoAnimated, do {
          size: 40
        }
    
        "Work Safe Zones", do {
          marginLeft: 10;
          font: 16, 400
          color: 0x116483
        }
      }
      
      right, do {
        if($.user)
          clickable, do {
            // onClick = signOut;
            padding: 10, 14;
            font: 14;
            color: 0x444;
            cursor: pointer;
            flexAlign: center, right;

            "My Account", do {
              marginRight: 13;
              font: 12;
              color: 0x888;
            };
    
            !img, do {
              src = $.user.profile.picture;
              radius: 15
              size: 30
            }
          }
      }
    }
  }
}