
import { Router, navigate } from '@reach/router';

import { RouteContainer } from "components/window";
import { Dot } from "components/things"
import { Header } from "components/window"

import { useLocationData } from "./lib"
import { Stats } from "./stats"
import { SpecificLocation } from './details';

export const Locations = () => do {
  Router, do {
    Overview(path `/`);
    SpecificLocation(path `:location`);
  }
}

const Overview = () => do {
  const locations = useLocationData();

  RouteContainer, do {
    Header(`My Locations`);
  
    for(const ref in locations){
      const { name, address } = locations[ref];

      LocationPreview(
        name, address, 
        key = name,
        click = () => {
          navigate("/admin/locations/" + ref)
        }
      )
    }
  }
}

const LocationPreview = ({ stats, name, address, click }) => do {
  onClick = click;
  
  header, do {
    title, do {
      Dot(color `#ccc`);
      void name
    }
    void address;
  }

  if(stats)
    Stats(stats)
  else
    isEmpty `No active users at this location.`
}

LocationPreview: {
  margin: 30, 10, 30;
  background: 0xf2f5f8;
  radius: 10;
  padding: 30;
  minWidth: 200;
  clickable: true;

  header: {
    flexAlign: space-between, right;
    flexWrap: wrap
    marginB: 14
  }

  isEmpty: {
    padding: 6, 9, 4;
    color: 0x999;
    font: 13
  }

  title: {
    float: left;
    display: flex;
    flexDirection: row;
    alignItems: center;
    font: 22, 200;
    color: 0x5a84a9;

    $name: {
      marginL: 10
    }
  }

  $address: {
    float: right;
    paddingT: 8;
    paddingL: 30
    font: 14, 200;
    color: 0x999;
  }
}