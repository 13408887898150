import firebase from "firebase/app"
import { useConstant, useRefresh } from "@gabeklein/hooks";

import { parseParameters } from "lib"
import { 
  toDatabase, 
  inDatabase, 
  fromDatabase 
} from "lib/firebase";

export function useInviteProcess() {
  const update = useRefresh();
  const state = useConstant(() => {
    const { search } = window.location;

    if (!search)
      return { current: "error" }

    const { invite, org } = parseParameters(search);

    if (!invite || !org)
      return { current: "error" };

    return {
      org,
      trySignin
    }

    async function trySignin(){
      state.thinking = true;
      update();

      try {
        const { given_name } = await authorizeUserData(state, invite);
        state.firstname = given_name;
        state.current = "done";
      }
      catch(err){
        if(err.message == "exists"){
          state.current = "exists"
          state.firstname = err.existingUser.given_name
        }
        else 
          state.prompt = err.message;
      }

      state.thinking = false;
      update();
    }
  });

  return state;
}

export async function authorizeUserData(state, invite) {
  const { uid, profile } = await signInWithPopup();
  
  const existing = await inDatabase(`account/${uid}`);

  if(existing){
    const err = new Error("exists");
    err.existingUser = profile;
    throw err;
  }
  
  const {
    email: invite_email,
    shift,
    position,
    admin,
    orgSlug
  } = await fromDatabase(`account_pending/${invite}`);


  const { name, picture, given_name, email } = profile;

  const db_entry = {
    org: orgSlug,
    policy: `${position}/${shift}`,
    profile: {
      name,
      picture,
      email: invite_email
    }
  }

  if (invite_email !== email)
    db_entry.profile.login_email = email;

  if(admin)
    db_entry.admin = true;

  await toDatabase(`account/${uid}`, db_entry)

  return { given_name };
}

async function signInWithPopup(){
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope(
    "https://www.googleapis.com/auth/userinfo.profile"
  )
  provider.setCustomParameters({
    prompt: 'select_account'
  });
  await firebase.auth().setPersistence(
    firebase.auth.Auth.Persistence.NONE
  )
  
  const {
    additionalUserInfo: { profile },
    user: { uid }
  } = await firebase.auth().signInWithPopup(provider);

  return { uid, profile }
}