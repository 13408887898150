import { RouteContainer, Header } from "components/window"

export const Position = () => do {
  WIP `Device Profiles`
}

export const Settings = () => do {
  WIP `Settings`
}

export const Support = () => do {
  WIP `Support`
}

const WIP = ({ children }) => do {
  RouteContainer, do {

    Header > children
    stuff, do {
      flexAlign: center;
      height: 500
      "👷🏻‍♂️ Page under construction 🚧", do {
        fontSize: 20
      }
    }
  }
}