export const Button = (props) => do {

  const { label, img, className, color, onClick } = props;

  + className;
  + onClick;

  marginL: 10;
  color: 0xe29696
  background: 0xf2f5f8;
  border: 0xffbfbf;

  font: 12, 500;
  paddingV: 8
  paddingH: 16;
  radius: 5;
  clickable: true
  flexAlign: center;
  opacity: 0.5

  onHover: {
    opacity: 1
  }

  icon_thing: {
    size: 12;
    WebkitMaskImage: `url(${img})`
    background: (color);
    marginR: 10;

  }

  if(img)
    icon_thing;
    
  void label
}

export const Header = ({ children }) => do {
  title, do {
    color: 0x808080;
    fontWeight: 400;
    marginB: 14
    font: 25;
    background: 0xfbfcfc
    void children;
  }

  hr, do {
    height: 1;
    bg: 0x15;
    marginBottom: 20;
  }
}

export const ZoneHeader = ({ name, inherits })  => do {
  float: left;
  display: flex;
  flexDirection: row;
  alignItems: center;
  flexWrap: wrap

  title, do {
    display: flex;
    flexDirection: row;
    alignItems: center;

    icon, do {
      background: 0x5a84a9;
      size: 22;
      flexShrink: 1;
      WebkitMaskImage: "url(/logo.svg)";
    }

    name, do {
      void name;

      marginH: 10, 10
      font: 22, 200;
      color: 0x5a84a9;
    }  
  }
  
  if(inherits)
    for(const also of inherits)
      inheriting, do {
        void also;
        
        marginT: 5
        marginL: 10
        font: 12, 500;
        color: 0x5a84a9;
        bg: 0x09;
        padding: 5, 10
        radius: 4;
      }
}