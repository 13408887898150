import { Checkbox } from "@material-ui/core"
import { useConstant } from "@gabeklein/hooks";

import { trySignInWithGoogle } from "lib/signin";
import { LogoAnimated } from "components/logo"
import { ButtonPending } from "../../components/ui";
import { useState } from "react";

export const Login = ({ onDidLogin }) => do {
  const [ pending, setPending ] = useState(false);
  const [ error, setError ] = useState(false)

  const state = useConstant(() => ({
    shouldRemember: false
  }));

  function signInWithGoogle(){
    setPending(true);
    setError(false);

    trySignInWithGoogle(state.shouldRemember)
      .then(onDidLogin)
      .catch(({ message }) => {
        setPending(false);
        setError(message);
      })
  }

  absolute: fill;
  flexAlign: center;

  contanier, do {
    width: 180;
    paddingH: 80;
    paddingV: 72
    flexAlign: center, column;
    textAlign: center;

    background: white;
    shadow: 0x05, 5, 1;
    border: 0x08;
    radius: 8;
    WebkitUserSelect: none;

    fontFamily: "Roboto"
    font: 12, 400;
    color: 0x888;

    LogoAnimated, do {
      size: 52;
      marginB: 10;
    }

    wsz `Work Safe Zones`, do {
      font: 22, 300;
      marginV: 12;
      color: 0x555;
      lineHeight: 16
    }

    "Workplace management panel", do {
      font: 12, 400;
      color: 0x888;
      marginB: 30;
    }
    
    if(!error)
        "Login with Google to access your company's account"
    else
      errorMessage, do {
        color: 0xdf7777;
        marginB: 15;

        void error, do {
          marginBottom: 15;
        };
        "Please try again"
      }

    ButtonPending, do {
      + pending
      label = `Sign In with Google`
      onClick = signInWithGoogle
      
      width: 150 
    }

    rememberMe, do {
      flexAlign: stretch, right;

      Checkbox(
        id `rememberMeButton`,
        onChange = (x) => {
          state.remember = x.target.checked
        }
      )

      !label, do {
        flexAlign: center, right;
        textAlign: left;

        htmlFor = "rememberMeButton"
        void `Remember me on this computer.`
      }
    }
  }
}