import { navigate } from '@reach/router';
import { useRefresh } from '@gabeklein/hooks';
import { sleep } from 'good-timing';
import { useState, useRef } from 'react';

import { RouteContainer, Section, Header } from "components/window";
import { Toggle, IconButton } from "components/interact"
import { NiceInput } from "components/input"
import { Icon } from "components/things"

import { toDatabase } from 'lib/firebase';
import {
  cache,
  removeAppFromPolicy,
  addAppToWhitelist,
  icon60,
  deletePosition
} from "./lib"

import { Shifts } from "./shifts"

const { entries } = Object;

const SYSTEM_ITEMS = {
  messages: "Messages",
  browser: "Internet"
}

app: {
  flexAlign: center;
  marginT: 15;
  marginR: 15;
  height: 44;
  clickable: true;
  font: 12, 500;
  transition: "transform 0.1s ease";
  radius: 8;
  border: 0x1;
  paddingH: 14;
  paddingV: 4;
  color: 0x777
  bg: 0xFFF6;

  onHover: {
    border: 0xfdcaca
    color: 0xa76464
  }
}

SpecificPosition: {
  infoContainer: {
    margin: 30, 10, 0;
    background: 0xf2f5f8;
    radius: 10;
    padding: 30;
  }

  header: {
    flexAlign: space-between, right;
    alignItems: center;
    marginB: 5
    paddingH: 20;
  }

  line: {
    height: 1
    marginTop: 25
    background: 0x09
  }

  infoBody: {
    flexAlign: right;
    flexWrap: wrap;
    position: relative;
    overflow: hidden

    after: {
      absolute: fill-right, 0, 20;
      width: 2;
      background: 0xf2f5f8;
    }
    permissions: {
      position: relative;
      flex: 1;
      minWidth: 320;
      flexGrow: 10
      marginR: 20;

      after: {
        absolute: fill-right;
        top: 20
        borderR: 0x1
        width: 0
      }
    }
    shifts: {
      minWidth: 300;
      flexGrow: 1
    }
  }
}

export const SpecificPosition = ({ position }) => {
  const { policies } = cache;

  if(!policies){
    sleep(100, () => {
      navigate("admin/position");
    })
    return false
  }

  const stats = policies[position];

  return do {
    RouteContainer, do {
      Header(`Manage Position`);
  
      infoContainer, do {
        header, do {
          HeaderEditable(...stats)
          IconButton, do {
            void `delete`
            icon = `x`
            color = `#ffbfbf`
            onClick = () => deletePosition(policies, position)
          }
        }
  
        line;

        infoBody, do {
          permissions >>>
            WhiteList(...stats),
            System(...stats)
            
          shifts >>
            Shifts(...stats)
        }
      }
    }
  }
}

const WhiteList = (props) => do {
  const refresh = useRefresh();

  Section, do {
    label = "App Whitelist"

    header = do {
      IconButton `Search for app` (
        icon `search`,
        click = () => {
          navigate(`admin/position/${props.id}/apps`)
        }
      ) 
  
      IconButton `Add package name`, 
        icon `plus`,
        click = async () => {
          const packagename = prompt("Please provide package name.");
          if(!packagename)
            return;

          const name = prompt("What label would you like to use?");
          if(!name)
            return;

          addAppToWhitelist(props.id, {name, id: packagename, img: false});
          refresh();
        }
    }

    if(props.apps.length)
      for(const app of props.apps){
        let { name, icon } = app;
        const app_name = name.split(/\s+-/)[0];

        app, do {
          position: relative;
          overflow: hidden
          clickable: true

          onClick = async () => {
            await removeAppFromPolicy(props, app)
            refresh();
          }

          onHover: {
            closeIndicator: {
              transform: "translateX(0)"
              opacity: 1
            }
          }

          closeIndicator, do {
            absolute: fill-right;
            width: 50;
            background: 0xf8f9fb
            shadow: 0xf8f9fb, 10
            transform: "translateX(10px)"
            opacity: "0"
            flexAlign: center;
            transition: "opacity 0.2s cubic-bezier(.31,.36,.05,1), transform 0.1s cubic-bezier(.31,.36,.05,1)"

            Icon(icon `x`, color `#fdcaca`), do {
              size: 13;
            }
          }

          icon, do {
            background: 0x3;
            radius: 5;
            overflow: hidden;
            flexAlign: center;

            !img, do {
              size: 30;
              opacity: 0.8
              src = icon ? icon60(icon) : "/smartphone.svg";
  
              if(!icon){
                boxSizing: border-box;
                padding: 4;
                opacity: 0.5;
              }
            }
          }

          void app_name, do {
            marginH: 22, 16
          }
        } 
      }
    else 
      "No apps enabled on this position."
  }
}

const System = ({ system, id, org }) => do {
  Section, do {
    label = "System"

    for(const [allowance, name] of entries(SYSTEM_ITEMS)){
      Toggle( 
        init = system.indexOf("system." + allowance) >= 0,
        app = name,
        onChange = (val) => {
          toDatabase(
            `/org/${org}/policies/${id}/whitelist/system-${allowance}`, 
            val || null
          )
        }
      )
    }
  }
}

const HeaderEditable = ({ name, org, id }) => do {
  const [ mode, setMode ] = useState(name === "New Position" ? "edit" : false);
  const [ active, setActive ] = useState(false);
  const [ label, setName ] = useState(name);
  const ref = useRef();

  title, do {
    display: flex;
    flexDirection: row;
    alignItems: center;
    height: 35

    icon, do {
      background: 0x5a84a9;
      size: 22;
      flexShrink: 1;
      WebkitMaskImage: "url(/logo.svg)";
    }

    if(mode == "edit"){
      NiceInput, do {
        + ref
        placeholder = active ? `Rename ${label}` : label
        onFocus = () => setActive(true)
        onBlur = () => setActive(false)
        onReturn = async (value) => { 
          if(!/[\w ]+/.test(value)){
            setMode(false);
            return;
          }
          await toDatabase(`/org/${org}/policies/${id}/name`, value)
          setName(value);
          setMode(false);
        }
        marginL: 10
      }
    }
    else {
      label, do {
        onClick = () => setMode("edit");
        void label;

        marginH: 10, 10
        font: 22, 200;
        color: 0x5a84a9;
        position: relative;
        
      }  
      Icon, do {
        icon = `edit`
        color = `#aaa`
      }
    }
  }
}