import 'components/bootstrap';

import { Router, navigate } from '@reach/router';
import { hot } from 'react-hot-loader/root';
import { Admin } from './admin';
import { Onboard } from './pages/onboard';

import { Login, AuthRedirect } from "./pages/login"

const App = () => do {
  Router, do {
    AuthRedirect, path `/`
    Login, path `/login`, 
      onDidLogin = () => navigate("admin/people");

    Onboard, path `/welcome/*`;
    Admin, path `/admin/*`;
  }
};

export default hot(App);