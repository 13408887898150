import { IconButton } from "components/interact"
import TimeField from "react-simple-timefield";
import { range } from "lib"
import { Form, Field } from 'react-final-form';
import arrayMutators from "final-form-arrays"
import { FieldArray } from 'react-final-form-arrays';

export const NewShift = ({ onSubmit, onCancel }) => do {
  Form(
    onSubmit,
    onCancel,
    initialValues = {
      start: "09:00",
      hours: 8,
      breaks: [ 30, 10, 20 ]
    },
    mutators = arrayMutators,
    component = ShiftFields
  )
}

const ShiftFields = ({ handleSubmit, onCancel }) => do {
  submit, do {
    flexAlign: right;
    
    IconButton `Cancel`,
      icon `x`,
      onClick = onCancel

    IconButton `Save`,
      icon `check`,
      onClick = handleSubmit
  }

  form, do {
    block, do {
      start `Start Time`, do {
        marginR: 10;
      };
  
      Field(
        name `start`,
        render = ({ input }) => do {
          TimeField(...input,
            style = { width: "45px" },
            do {
              use: Field
              font: 16
              marginR: 10
            }
          )
        }
      )
      
      dur `Duration`, do {
        marginH: 15, 10;
      };
  
      Field.sel, do {
        component = "select";
        name = "hours"
        value = 8;
  
        width: 86
  
        for(let i of range(1, 16)){
          const hr = i > 1 ? "hours" : "hour";
          !option `${i} ${hr}`, 
            value = i
        }
      }
    }
  
    block, do {
      "Breaks", do {
        marginR: 10;
      };
  
      FieldArray(name = "breaks"),
        ({ fields }) => fields.map((name) => do {
          Field.sel, do {
            component = "select";
            key = name;
            + name
  
            width: 60
  
            for(const i of range(12).map(x => x * 5))
              !option(void `${i || "-"}`), value = i, do {
                width: 50
              }
          }
        })
    }
  }
}

ShiftFields: {
  submit: {
    width: 80
  }

  form: {
    flexAlign: down;
    alignItems: flex-start;
    flexWrap: wrap
    flexShrink: 0
    marginT: 15;
    marginR: 15;
    paddingH: 25;
    paddingV: 8
    clickable: true;
    radius: 8;
    border: 0x1;
    color: 0x777
    bg: 0xFFF6;
    font: 12, 500;
  }

  block: {
    flexAlign: right;
    alignItems: center;
    marginV: 8
    marginR: 15;
  }

  Field: {
    font: 16
    marginH: 10
    radius: 0
    border: none;
    bg: transparent;
    paddingBottom: 5;
    borderBottom: 0x1, 2;
    outline: none;
    color: 0x555;
    paddingR: 0
    color: 0x888
  }
}
