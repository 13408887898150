import { useRefresh } from '@gabeklein/hooks';
import { useState } from 'react';
import { Section } from "components/window";
import { IconButton } from "components/interact"

import { addNewShift, removeShift } from './lib';
import { NewShift } from './shift_new';

export const Shifts = (props) => do {

  const [ mode, setMode ] = useState(false);
  const refresh = useRefresh();
  const shifts = Object.entries(props.shifts || {});

  Section, do {
    label = "Shifts"
    header = do {
      flexAlign: right;

      if(mode != "new")
        IconButton `Add new shift`,
          icon `plus`,
          onClick = () => setMode("new")
    }

    container, do {
      flex: 1
      
      if(mode == "new"){
        NewShift, do {
          onCancel = () => setMode(false)
          onSubmit = async (values) => {
            const { hours, start, breaks } = values;
            await addNewShift(props, { 
              duration: hours * 60,
              start: start.replace(":", ""),
              breaks: breaks.filter(x => parseInt(x)).join(", ")
            })
            setMode(false)
          }
        }
      }

      if(shifts.length)
        for(const [id, shift] of shifts)
          ShiftItem(
            ...shift, 
            onClickDelete = async () => {
              await removeShift(props.org, props.id, id)
              delete props.shifts[id];
              refresh();
            }
          )
      else
        noneFound `No shifts currently exist for this position.`, do {
          color: 0xccc
          paddingT: 20;
          paddingL: 10;
          font: 12, 400
        }
    }
  }
}

const ShiftItem = ({ duration, start, breaks, onClickDelete }) => do {
  let hour = parseInt(start.slice(0, 2));
  const min = start.slice(2);
  const suffix = hour > 12 ? (hour -= 12, "pm") : "am";
  const minutes = duration % 60;
  const hours = Math.floor(duration / 60);

  inner: {
    display: inline-block;
    radius: 4;

    padding: 2, 8;
    bg: "rgba(0,0,0,0.05)";
  }

  display: flex;
  alignItems: center;
  marginT: 15;
  marginR: 15;
  paddingH: 25;
  paddingV: 4;
  clickable: true;
  radius: 8;
  border: 0x1;
  color: 0x777
  bg: 0xFFF6;
  height: 44;
  font: 15, 500;
  flexGrow: 1;
  textAlign: left;
  justifyContent: flex-start;
  position: relative
  overflow: hidden
  cursor: initial

  onHover: {
    closeIndicator: {
      transform: "translateX(0)"
      opacity: 1
    }
  }

  section, do {
    width: 90
    inner.startTime `${hour}:${min}${suffix}`;
  };
  section2, do {
    width: 70
    inner.duration `${hours}:${ minutes || "00" }`;
  } 
  section3 >> inner.breaks > breaks;

  closeIndicator, do {
    void "delete";

    onClick = onClickDelete

    absolute: fill-right;
    width: 60;
    background: 0xf8f9fb
    shadow: 0xf8f9fb, 10
    transform: "translateX(10px)"
    opacity: "0"
    flexAlign: center;
    radius: 10
    border: 0xf8f9fb, 5
    cursor: pointer
    color: 0xfdcaca
    transition: "background 0.2s cubic-bezier(.31,.36,.05,1), opacity 0.2s cubic-bezier(.31,.36,.05,1), transform 0.1s cubic-bezier(.31,.36,.05,1)"

    onHover: {
      color: 0xea5f5f
    }
  }
}