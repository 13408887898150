import { useRefresh } from "@gabeklein/hooks";
import { fromDatabase, checkForUser, onValueQuery } from "lib/firebase"
import { useDidMount } from '@gabeklein/hooks';
import { useState } from "react"

export const cache = {
  users: {},
  shifts: {},
  heartbeats: {},
  orgs: {}
};

export function useUserDetails(uid) {
  const refresh = useRefresh()
  let stats = cache.users[uid];
  let org = stats && cache.orgs[stats.org];

  if (!stats) {
    fromDatabase(`/account/${uid}`)
      .then(async user => {
        stats = cache.users[uid] = user;
        user.uid = uid;
        cache.orgs[stats.org] = 
          await fromDatabase(`/org/${stats.org}`)
        refresh();          
      })
  }
  else if(!org){
    console.error("Escape hatch loaded org")
    fromDatabase(`/org/${stats.org}`)
      .then(org => {
        cache.orgs[stats.org] = org;
        refresh();
      })
  }

  return {
    user: stats,
    org
  };
}

export function onHeartbeats(org, update) {
  let data = cache.heartbeats;
  onValueQuery('heartbeats', 'org', org, heartbeats => {
    cache.heartbeats = heartbeats;
    update()
  })
  return data;
}

export function useOrg() {
  const [data, setData] = useState({});
  useDidMount(async () => {
    const user = await checkForUser();
    if (user) {
      const org = await fromDatabase(`org/${user.org}`)
      setData({ orgSlug: user.org, org });
    }
  })
  return data;
}
