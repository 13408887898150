

export const Button = (props) => do {
  const { onClick, className, children, disabled } = props;
  
  + className;
  + onClick

  WebkitUserSelect: none;
  cursor: pointer;
  font: 12, 500;
  flexAlign: center;
  boxSizing: border-box;
  transition: "all 0.1s ease-in-out";
  padding: "1.1em", "1.8em"
  marginT: 30

  if(!disabled){
    onHover: {
      transform: scale(1.03)
    }
  
    onActive: {
      transform: scale(0.99)
    }
  }

  void children;
}

GreyClickable: {
  border: 0x07
  marginR: 15
  marginT: 15
  paddingH: 26, 36;
  boxSizing: border-box;
  height: 50
  clickable: true
  radius: 13
  bg: 0xf2f5f8
  color: 0x666;
  flexAlign: space-between, right;
  alignItems: center;
}

export const GreyClickable = ({ children, className, ...props }) => do {
  ++ props;
  + className;
  void children;
}

export const StatusCell = ({ children, color, className }) => do {
  + className;

  use: GreyClickable

  if(color === false)
    unknown `?`, do {
      font: 13, 800;
      color: 0xD26A54
      marginH: 8, 14
    }
  else
    Dot, do {
      + color;
      marginR: 15
    };

  void children;
}

export const Dot = ({ color, className }) => do {
  + className;
  flexAlign: center;
  minWidth: 20
  
  indicator, do {
    style = color ? { background: color } : {};
    size: 9;
    radius: 10;
    background: "#52bc52";
  }
}

export const Checkbox = ({ checked }) => do {
  size: 15
  radius: 5;
  display: inline-block;
  verticalAlign: middle;
  border: 0xddd;
  background: 0xeee
  position: relative

  if(checked){
    Icon(
      icon `check`
    ), do {
      size: auto
      background: 0x72ade5
      absolute: fill, 2
    }
  }
  else {
    bg: 0xFFF1
  }
}

export const Icon = ({ className, icon, color }) => {
  const style = {
    WebkitMaskImage: `url(/${icon}.svg)`
  };

  if(color)
    style.background = color;

  return do {
    + className;
    + style
    display: inline-block;
    background: 0xfff9;
    size: 14;
    verticalAlign: middle;
    WebkitMaskRepeat: no-repeat;
  }
};