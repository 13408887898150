import { RouteContainer } from "components/window";
import { Section, Header } from "components/window";
import { Dot } from "components/things"

import { useUserDetails } from "./lib"
import { toDatabase } from "lib/firebase";
import { SelectShift } from "components/shifts";;

export const ZoneHeader = ({ name }, ...children) => do {
  float: left;
  display: flex;
  flexDirection: row;
  alignItems: center;
  justifyContent: space - between;
  flexWrap: wrap

  left, do {
    display: flex;
    flexDirection: row;
    alignItems: center;
    flexWrap: wrap
    title, do {
      display: flex;
      flexDirection: row;
      alignItems: center;

      Dot(color = "#ccc")

      name, do {
        void name;

        marginH: 10, 10
        font: 22, 200;
        color: 0x5a84a9;
      }  
    }
    
    // inheriting, do {
    //   void "not on-site";

    //   marginT: 5
    //   marginL: 10
    //   font: 12, 500;
    //   color: 0x999;
    //   bg: 0x09;
    //   padding: 5, 10
    //   radius: 4;
    // }
  }

  void children;
}

export const SpecificUser = (props) => do {
  const { userID } = props;
  const { user: stats, org } = useUserDetails(userID);
  
  if(!stats || !org)
    return x;

  RouteContainer, do {
    Header(`User Details`);

    if (stats)
      policyZone, do {
        margin: 30, 10, 0;
        background: 0xf2f5f8;
        radius: 10;
        padding: 30;

        header, do {
          flexAlign: space - between, right;
          alignItems: center;
          marginB: 5
          paddingH: 20;

          ZoneHeader, do {
            name = stats.profile.name;
          }
        }

      line, do {
        height: 1
        marginTop: 25
        background: 0x1
      }

      info, do {
        paddingV: 10;

        email, do {
          marginL: 20
          marginT: 10;
          font: 14, 500;

          void stats.profile.email, do {
            display: inline;
            color: 0x5a84a9;
            padding: 5, 10;
            background: 0x08
            radius: 5
          }
        }
      }

      line, do {
        height: 1
        marginTop: 14
        background: 0x1
      }

      Section, do {
        // label = "Applied "
        label = do {
          spacer, do {
            height: 10
          }
        }

        if(stats)
          SelectShift(
            onChange = async (value) => {
              await toDatabase(`/account/${userID}/policy`, value)
            }, 
            initial = stats.policy || "", 
            policies = org.policies
          )
      }
    }
  }
}

// const EditUI = ({ state }) => do {
//   if(state.edit)
//     Button, do {
//       label = `save`
//       color = `green`
//       color: green
//       border: 0x93ca93;
//       onClick = () => {
//         state.edit = false
//       }
//     }
//   else
//     Button, do {
//       label = `edit`
//       img = `/settings.svg`
//       color = `#777`
//       color: 0x777
//       border: 0xccc;
//       onClick = () => {
//         state.edit = true
//       }
//     }
// }
