export const LogoAnimated = ({ className }) => do {
  + className

  position: relative;
  bg: 0x0076a3;
  WebkitMaskImage: "url(/logo.svg)"
  transformOrigin: "50% 50%"
  // animation: "rotation 6s infinite linear"
}

export const SVGLogo = () => do {

  path: {
    transformOrigin: "50% 50%"
    animation: "rotation 2s infinite linear"
    absolute: fill;
    fill: none;
    stroke: 0x0076a3;
    strokeLinecap: round;
    strokeWidth: 32;
    fillRule: evenodd;
  }

  !svg, do {
    viewBox = "0 0 855 855";
    !path, d="M267.007,161.5C414.468,73.965,604.969,122.545,692.5,270.007S731.455,607.969,583.993,695.5,246.031,734.455,158.5,586.993c-52.546-88.519-56.042-192.549-18.544-280.5"
    !path, d="M814.942,491.678c-34.616,214.807-236.814,360.881-451.62,326.264S2.441,581.128,37.058,366.322,273.872,5.441,488.678,40.058C617.626,60.838,721.8,142.006,777,250.039"
    !path, d="M206.842,502.822c-40.771-121.038,24.3-252.21,145.336-292.98s252.21,24.3,292.98,145.336-24.3,252.21-145.336,292.98A230.933,230.933,0,0,1,292.64,617.951"
  }
}