import { useMemo, useState, forwardRef } from "react";

input: {
  border: none;
  bg: transparent;
  paddingBottom: 5;
  borderBottom: 0x1, 2;
  outline: none;
  font: 1.0;
  color: 0x555;
}

export const Input = ({
  children,
  placeholder = children, 
  className,
  ...props 
}) => do {
  !input(
    ...props, 
    className,
    placeholder
  )
}

export const NiceInput = forwardRef((props, ref) => do {
  const { 
    children,
    placeholder = children, 
    className,
    onFocus,
    onBlur,
    onKeyUp,
    onReturn = () => false,
    ...rest 
  } = props;

  + className;

  const [ active, setActive ] = useState(false);

  position: relative;
  color: 0x666;

  void placeholder, do {
    absolute: top-left, 6;
    font: 0.7;
    pointerEvents: none;
    transition: "all 0.12s cubic-bezier(.31,.36,.05,1)"

    if(active){
      color: 0x2
      transform: "translate3d(-10px, -1.8em, 0) scale(0.9);"
    }
  }

  !input(ref, ...rest), do {
    onKeyUp = !onKeyUp
      ? (e) => { if (e.keyCode == 13) onReturn(e.target.value, e) }
      : (e) => { if (e.keyCode == 13 && onKeyUp(e) !== false) onReturn(e.target.value, e); }
  
    onFocus = !onFocus 
      ? ( ) => { setActive(true); }
      : (e) => { setActive(true); onFocus(e) }
  
    onBlur = !onBlur
      ? (e) => { if (!e.target.value) setActive(false); }
      : (e) => { if (!e.target.value) setActive(false); onBlur(e) }
  }
})