import { Link, Location } from '@reach/router';
import { useState } from 'react';

export const SideBar = ({ children }) => do {
  Location, (route) => do {
    const { pathname } = route.location;
    if(pathname !== "/login" && pathname !== "/")
      NavBar(children);
  }
}

const NavBar = ({ children, className }) => do {
  fixed: fill-top;
  zIndex: 3
  height: 0;
  fontFamily: Cantarell
  font: 13, 600;
  textAlign: left;
  WebkitUserSelect: none;

  center, do {
    height: 0;
    maxWidth: 1280;
    margin: auto;
    position: relative;
    bg: blue

    sidebar(className), do {
      paddingTop: 80;
      width: 225;
      background: 0xF2F5F7;
      position: relative;
      left: 0;
      top: 0;
      height: 100, vh;
      boxSizing: border-box;
      overflow: hidden

      after: {
        absolute: fill-left, 6, 2;
        shadow: 0x1, 3, -1, 0
        bg: 0xf3f3f3;
        width: 1
      }

      void children;
    }
  }
}

export const NavLink = (props) => do {
  const [ active, setActive ] = useState();
  const {
    size,
    to: label,
    pre,
    icon: image, 
    url = '/' + label.toLowerCase()
  } = props;

  Link, do {
    to = pre && pre + url || url;
    
    // @reach/router doesn't have a hook for this :(
    className = active && "active";
    getProps = ({ isCurrent }) => {
      setActive(isCurrent);
    }

    display: block;
    paddingLeft: 50
    cursor: pointer;
    marginBottom: 14;
    paddingV: 10;
    color: 0x888
    transition: 'background 0.1s cubic-bezier(.31,.36,.05,1)'
    position: relative;
    textDecoration: none;

    css: active: {
      background: 0xFFFA;
      color: 0x5a84a9

      icon_thing: {
        background: 0x5a84a9
      }

      cutout: {
        transform: translateX('0px');
        triangle: {
          shadow: 0x10, 2, 0, 1
        }
      }
    }

    onHover: {
      background: 0xFFF6;
      inner: {
        transform: "translateX(3px)"
      }
      icon_thing: {
        transform: 'translateX(2px)'
      }
      triangle: {
        shadow: 0x10, 3, 0, 1
      }
    }

    inner, do {
      transition: 'transform 0.17s cubic-bezier(.31,.36,.05,1)'
      flexDirection: row;
      alignItems: center;
      display: flex;

      icon_thing, do {
        background: 0xBBB;
        size: (size || 24);
        WebkitMaskImage: (`url(/${image}.svg)`);
      }
      
      void label, do {
        marginL: 13
      }
    }
  }
}