import { useDidMount } from '@gabeklein/hooks';
import { LinearProgress } from "@material-ui/core"
import { navigate } from '@reach/router';
import { sleep } from 'good-timing';

import { LogoAnimated as LogoActuallyNotAmimated } from "components/logo";
import { checkForUser } from 'lib/firebase';

export const AuthRedirect = () => {
  useDidMount(async () => {
    const user = await checkForUser();
    if(user)
      navigate("/admin/people")
    else {
      await sleep({ sec: 1.3 })
      navigate("/login")
    }
  })

  return do { Loading } 
}

const Loading = () => do {
  container, do {
    onClick = () => navigate("/login")

    LogoActuallyNotAmimated;
    header `Work Safe Zones`;
    sub `Workplace management panel`;
    prog, do {
      LinearProgress();
    }
  }
}

Loading: {
  flexAlign: center;
  height: 100, vh

  container: {
    flexAlign: center, column;
    minHeight: 340
    width: 350;
    radius: 6;
    fontFamily: "Roboto"
  }

  LogoActuallyNotAmimated: {
    size: 72
  }

  header: {
    font: 22, 300;
    marginV: 12;
    color: 0x555;
    lineHeight: 16
  }

  sub: {
    font: 12, 400;
    color: 0x888;
  }

  prog: {
    height: 2.5
    opacity: 0.5
    paddingH: 20
    marginT: 50
    overflow: hidden;
    width: 220
  }
}