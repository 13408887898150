import { useDidMount } from '@gabeklein/hooks';
import { intoDatabase, checkForUser, fromDatabase, toDatabase, getUser } from 'lib/firebase';
import { dashToCapital } from "lib";
import { useState } from 'react';
import { navigate } from '@reach/router';

const SEARCH_ENDPOINT = "https://us-central1-work-safe-zone-1518730203544.cloudfunctions.net/v1/search?q="

export const cache = {};

export const icon60 = (id) => `https://lh3.googleusercontent.com/${id}=s60`;
export const icon120 = (id) => `https://lh3.googleusercontent.com/${id}=s120`;
  
export function usePolicyData(){
  const [ policies, setPols ] = useState({});

  useDidMount(async () => {
    const user = 
      await checkForUser();

    const policies = 
      await fromDatabase(`/org/${user.org}/policies`) || []

    for(const id in policies)
      computePolicyDescriptor(policies[id], user.org, id);

    setPols(policies);
  })

  return cache.policies = policies;
}

export async function addNewShift(policy_data, data){
  const { policies } = cache;
  const { org, id } = policy_data;
  const { key } = await intoDatabase(`/org/${org}/policies/${id}/shifts/`, data);
  const policy = policies[id];
  if(!policy.shifts)
    policy.shifts = {};
  policy.shifts[key] = data;
}

export function removeShift(org, policy, shift){
  return toDatabase(`/org/${org}/policies/${policy}/shifts/${shift}`, null)
}

export async function addNewPosition(){
  const user = getUser();
  const entry = {
    name: "New Position",
    whitelist: {
      "system-browser": true
    },
    shifts: []
  };
  const { key } = await intoDatabase(`/org/${user.org}/policies/`, entry);
  computePolicyDescriptor(entry, user.org, key)
  cache.policies[key] = entry;
  navigate(`admin/position/${key}`)
}

export async function deletePosition(policies, id){
  delete cache.policies[id];
  await toDatabase(`/org/${getUser().org}/policies/${id}`, null)
  navigate("admin/position")
}

export function computePolicyDescriptor(item, org, id){
  const { whitelist } = item;

  const apps = item.apps = [];

  item.org = org;
  item.id = id;
  item.system = [];

  if(!item.shifts){
    item.shifts = {};
  }

  if(id === "default")
    id = org;
  
  if(!item.name)
    item.name = dashToCapital(id);

  if(whitelist)
    for(const ref in whitelist){
      const system = ref.split("system-")[1];

      if(system){
        let { name, icon } = whitelist[ref];
        if(name === true)
          name = ""
        item.system.push(system)
      }
      else {
        const { icon, name } = whitelist[ref]
        apps.push({
          name,
          icon,
          ref
        })
      }
    }
}

export async function searchPlayStore(query){
  const request = fetch(
    SEARCH_ENDPOINT + encodeURIComponent(query), 
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  const res = await request;
  return await res.json();
}

export async function addAppToWhitelist(policy, app){
  const { org, id, apps } = cache.policies[policy];
  const { name, id: pkg, img } = app;

  apps.push({ name, ref: pkg, icon: img });
  const db_package = pkg.replace(/\./g, "-");
  await toDatabase(
    `org/${org}/policies/${id}/whitelist/${db_package}`, 
    { name, icon: img }
  )
}

export async function removeAppFromPolicy(policy, app){
  const { org, id, apps } = policy;
  await toDatabase(`org/${org}/policies/${id}/whitelist/${app.ref}`, null);

  const i = apps.indexOf(app);
  apps.splice(i, 1)
}