import { Field, Form } from "react-final-form";
import { useState } from "react";
import { sleep } from "good-timing";

import {Select} from 'final-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';

export const SelectShift = (props) => do {
  const { onChange, initial, policies } = props;

  const [ position, shift ] = initial.split("/");
  const [ isUpdated, setUpdated ] = useState(false);

  Form(
    onSubmit = async ({ position, shift }) => {
      await onChange(`${position}/${shift}`);
      setUpdated(true);
      await sleep({ sec: 1.4 })
      setUpdated(false);
    },
    initialValues = {
      position, 
      shift
    },
    component = OptionsFields,
    dataPolicies = policies,
    isUpdated
  )
}

export const ShiftSelection = ({ dataPolicies, values, shifts, policy }) => do {
  flexAlign: right

  Field, do {
    name = "position"
    label = "User Position"
    value = values.position
    component = Select
    formControlProps = do { 
      width: 180
    }

    marginR: 20;
    font: 12
    color: 0x555

    for(let id in dataPolicies){
      const position = dataPolicies[id];
      !MenuItem, value = id, void position.name;
    }
  }

  Field, do {
    name="shift"
    label="User Shift"
    disabled = !shifts
    component = Select
    formControlProps = do { 
      width: 200
    }

    marginR: 20;
    font: 12
    color: 0x555

    if(shifts)
      for(let id in shifts)
        !MenuItem(
          void quoteShift(shifts[id]),
          value = id
        )

    else if(!policy)
      !MenuItem `Select a Position`, selected = "selected"
    else
      !MenuItem `-- No Shifts --`, selected = "selected"
  }

  // XField, do {
  //   width: 150
  //   label = "User Position"
  //   name = "position"
  //   value = values.position

  //   for(let id in dataPolicies){
  //     const position = dataPolicies[id];
  //     !MenuItem, value = id, void position.name;
  //   }
  // }

  // XField, do {
  //   label = "User Shift"
  //   name = "shift"
  //   disabled = !shifts;

}

// const SelectInput = ({
//   input: { name, value, onChange, ...restInput },
//   meta,
//   label,
//   formControlProps,
//   ...rest
// }) => {
//   return (
//     <FormControl
//       {...formControlProps}
//       error={showError}
//       style={{
//         minWidth: "100%"
//       }}
//     >
//       <InputLabel htmlFor={name}>{label}</InputLabel>
//       <Select
//         {...rest}
//         name={name}
//         onChange={onChange}
//         inputProps={restInput}
//         value={value}
//       />
//       {meta.error && (
//         <FormHelperText>{meta.error}</FormHelperText>
//       )}
//     </FormControl>
//   );
// };

const XField = ({ label, value, className, children, id }) => do {
  Field, do {
    name=id
    + label
    + value
    componet=Select
    formControlProps = { className }

    void children
  }
}

const AField = (props) => do {
  const { disabled, label, value, className, children, id } = props;

  // debugger

  width: 200
  height: 40;
  marginR: 10;
  marginB: 10
  position: relative;

  Field: {
    width: 200
    height: 40;
    padding: 20, 10
    border: 0x12;
    outline: none
    MozAppearance: none;
    WebkitAppearance: none;
    appearance: none;
    lineheight: 20;
    color: 0x333;
    font: 12
  }

  $label: {
    display: block
    absolute: top-left, -20, 5;
    font: 12, 400;
    color: 0xaaa;
  }

  $value: {
    absolute: fill;
  }

  flexAlign: right

  + className;

  void label;
  // void value, do {

  // }
  Field, do {
    component = "select";
    name = id

    + value;
    + disabled;

    void children;
  }
}

const OptionsFields = (props) => do {
  const {
    values,
    handleSubmit,
    pristine,
    submitting,
    dataPolicies = {},
    isUpdated
  } = props;

  const policy = dataPolicies[values.position];
  const shifts = policy && policy.shifts;
  const disabled = false //pristine || submitting || !shifts || !shifts[values.shift];

  ShiftSelection(
    dataPolicies,
    values,
    shifts
  )

  !button, do {
      + disabled
      onClick = handleSubmit

      if(isUpdated)
        "Updated!"
      else 
        "Update"

      if(disabled){
        color: 0x4;
      }
      else {
        cursor: pointer;
        color: 0x5a84a9;
        font: 500
      }

      height: 40
      width: 120
      background: none;
      radius: 5;
      border: 0x12;
      paddingH: 20;
    };
}

const quoteShift = (shift) => {
  const { duration, start, breaks } = shift;

  let hour = parseInt(start.slice(0, 2));
  const min = start.slice(2);
  const suffix = hour > 12 ? (hour -= 12, "pm") : "am";
  const minutes = duration % 60;
  const hours = Math.floor(duration / 60);

  return `${hour}:${min}${suffix} | ${hours}:${ minutes || "00" } | ${breaks}`;
}