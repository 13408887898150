import { Checkbox } from "./things";
import { useState } from "react";

export const IconButton = ({ click, onClick, icon }, label) => do {
  onClick = click || onClick;

  marginR: 12;
  flexAlign: space-between, right;
  alignItems: center;
  padding: 8, 14
  clickable: true
  bg: 0x09;
  color: 0x8eadc7;
  radius: 4;
  font: 11, 500;
  transition: "transform 0.1s ease"

  onActive: {
    priority: 10
    transform: "translate3d(2px, 0, 0) scale(0.99)"
    transition: none
  }

  icon, do {
    marginR: 10
    background: 0x9db4ca
    size: 1.1;
    minSize: 1.1;
    WebkitMaskImage: `url(/${icon}.svg)`;
  }

  void label;
}

export const Toggle = ({ app, init, onChange = () => false }) => do {
  const [ active, setActive ] = useState(init !== undefined ? init : true);

  cell, do {
    flexAlign: center;
    marginT: 15;
    marginR: 15;
    height: 44;
    clickable: true;
    font: 12, 500;
    transition: "transform 0.1s ease";
    radius: 8;
    border: 0x1;
    paddingH: 20;
    paddingV: 4;
    color: 0x777
    bg: 0xFFF6;


    onClick = () => {
      setActive(!active)
      onChange(!active)
    }
    
    Checkbox(checked = active)
    void app, do {
      font: 12, 500;
      marginL: 15
    };
  }
}