import { useBeaconData, addBeacon, removeBeacon } from "./lib"
import { RouteContainer, Section, Header } from "components/window";
import { NiceInput } from "components/input"
import { Icon } from "components/things"
import { isAbsolute } from "upath";

export const SpecificLocation = (props) => do {
  const paths = props.location.pathname.split('/')
  const locationId = paths[paths.length - 1]
  const beacons = useBeaconData(locationId);

  RouteContainer, do {
    Header(`Location: ${locationId}`);

    Section, do {
      label = "Beacons"

      header = do {
        NiceInput, do {
          placeholder = `Enter Beacon UUID`
          onReturn = async (beaconId) => {
            await addBeacon(beaconId, locationId)
            window.location.reload()
          }
        }
      }

  
    for (const beaconId in beacons) {
        LocationPreview(
          beaconId,
          key = beaconId
        )
      }
    }
  }
  
};

  SpecificPosition: {
    positionZone: {
      margin: 30, 10, 0;
      background: 0xf2f5f8;
      radius: 10;
      padding: 30;
    }

    header: {
      flexAlign: space - between, right;
      alignItems: center;
      marginB: 5
      paddingH: 20;
    }

    line: {
      height: 1
      marginTop: 25
      background: 0x09
    }
  }

  const LocationPreview = ({ beaconId }) => do {
    title, do {
      void beaconId
    }

    onClick = async () => {
        await removeBeacon(beaconId)
      }

    onHover: {
      closeIndicator: {
        transform: "translateX(0)"
        opacity: 1
      }
    }

    closeIndicator, do {
      width: 50;
      background: 0xf8f9fb
      shadow: 0xf8f9fb, 10
      transform: "translateX(10px)"
      opacity: "0"
      float: right;
      position: relative;
      bottom: 20;
      left: 10;
      transition: "opacity 0.2s cubic-bezier(.31,.36,.05,1), transform 0.1s cubic-bezier(.31,.36,.05,1)"

      Icon(icon`x`, color`#fdcaca`), do {
        size: 13;
        clickable: true
      }
    }
  }
  
  LocationPreview: {
      margin: 30, 10, 30;
      background: 0xf2f5f8;
      radius: 10;
      paddingT: 15;
      paddingL: 20;
      minWidth: 200;

      title: {
        textAlign: center;
        display: flex;
        flexDirection: row;
        alignItems: center;
        font: 22, 200;
      }
    }