export const Header = ({ children }) => do {
  title, do {
    color: 0x808080;
    fontWeight: 400;
    marginB: 14
    font: 25;
    background: 0xfbfcfc
    void children;
  }

  hr, do {
    height: 1;
    bg: 0x15;
    marginBottom: 50;
  }
}

export const RouteContainer = ({ children, className }) => do {
  paddingTop: 85;
  paddingLeft: 245;
  boxSizing: border-box;
  minHeight: 100, vh
  position: relative;
  background: 0xFBFCFC;

  after: {
    content: "\"\"";
    absolute: fill-right, 76, 2;
    bottom: 20
    bg: 0xEFEFEF
    width: 1
  }

  container, do {
    + className;

    paddingV: 20, 100;
    paddingH: 30, 50
    textAlign: left;
    
    void children;
  }
}

export const Section = ({ label, header, children }) => do {
  margin: 40, 20, 20;
  font: 13, 500;
  
  void label, do {
    color: 0x888;
    marginBottom: 4;
  };

  if(header)
    header, do {
      marginT: 14
      marginL: 1
      flexAlign: right;
      alignItems: flex-end;
      justifyContent: baseline;

      void header;
    }

  content, do {
    display: flex;
    flexDirection: right;
    flexWrap: wrap

    if(typeof children == "string"){
      height: 61
      void children, do {
        padding: 25, 10, 0;
        font: 12, 400;
        color: 0xccc;
      }
    }
    else void children;
  }
}