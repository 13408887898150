import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { within } from "good-timing";

const PROD_CONFIG = {
  apiKey: "AIzaSyAHCIg9z3Lwh-tSPmjF2HTjvdwaKAUss20",
  authDomain: "work-safe-zone-1518730203544.firebaseapp.com",
  databaseURL: "https://work-safe-zone-1518730203544.firebaseio.com",
  projectId: "work-safe-zone-1518730203544",
  storageBucket: "work-safe-zone-1518730203544.appspot.com",
  messagingSenderId: "989494843655"
}

//TODO: move this stuff to environment vars or something...
firebase.initializeApp(PROD_CONFIG);

let activeUser;

export const getUser = () => activeUser;

export const setActiveUser = (data) => activeUser = data;

/**
 * Get reference where url matches `at`
 * 
 * @param at - url of database entry to get
 */
export const ref = (at) => firebase.database().ref(at);

/**
 * Put something in database.
 * 
 * WILL OVERWRITE ANY EXISTING DATA
 * 
 * @argument ref - object url of desired data
 * @argument content - value of new data
 * 
 */
export const toDatabase = (reference, content) =>
  ref(reference)
    .set(content);

/**
 * Insert sometihng into database with unique key.
 * 
 * @argument ref - location to place new data
 * @argument content - value of new data
 * 
 */
export const intoDatabase = (reference, content) =>
  ref(reference)
    .push(content)

/**
 * Get something from database.
 * 
 * @argument ref - object url of desired data
 * 
 * @returns data | false (when failed)
 */
export const fromDatabase = (reference) =>
  ref(reference)
    .once("value")
    .then(e => e.val())
    .catch(() => false)

/**
 * Get something from database.
 * 
 * @argument path - path to value to be removed
 * 
 * @returns data | false (when failed)
 */
export const removeFromDatabase = (path) =>
  ref(path).remove().then(() => window.location.reload())

/**
 * Assert if something is in database.
 * 
 * @argument ref - object url of desired data
 * 
 * @returns boolean
 */
export const inDatabase = (reference) =>
  ref(reference)
    .once("value")
    .then(e => e.exists())
    .catch(() => false)

/**
 * Return JSON object of filtered values within `from` which match specified paramters.
 * 
 * @param from - database url for collection to be queried
 * @param key - the key of value to test 
 * @param equalTo - value to qualify within filtered object 
 */
export const query = (from, key, equalTo) =>
  ref(from)
    .orderByChild(key)
    .equalTo(equalTo)
    .once("value")
    .then(x => x.val());

export const onValueQuery = (from, key, equalTo, cb) =>
  ref(from)
    .orderByChild(key)
    .equalTo(equalTo)
    .on("value", snap => cb(snap.val()))

/**
 * Get current user if exists.
 * 
 * @returns Promise - user | null
 */
export const checkForUser = async () => {
  if (activeUser)
    return activeUser;

  return await within({ sec: 3 }, resolve => {
    const unsub = didLoadUser(
      user => { unsub(); resolve(user) }
    );
  })
}

/** 
 * Delete local firebase login credentials.
 */
export const signOut = () =>
  firebase.auth().signOut()
    .then(() => console.log("signed out"));

/**
 * Set callback for when firebase detects a user change.
 * Will fire callback immediately with current value.
 * 
 * @argument callback
 * 
 * @returns removeEventListener
 */
export const authDidChange = callback =>
  firebase.auth().onAuthStateChanged(callback);

/** 
 * Set callback for when firebase user changes.
 * Callback receives current-user entry from database.  
 * 
 * @argument callback (User entry from DB)
 * 
 * @returns removeEventListener
 */
export const didLoadUser = callback =>
  authDidChange(async (user) => {
    if (!user)
      callback(null);
    else {
      const entry = activeUser = await fromDatabase(`/account/${user.uid}`);
      entry.uid = user.uid;
      entry.profile.picture = user.photoURL;
      callback(entry)
    }
  })

