import { CircularProgress } from "@material-ui/core"

export const ButtonPending = ({
  onClick,
  className,
  disabled,
  pending,
  label,
  children
}) => do {

  + className;
  onClick = (e) => { !disabled && !pending && onClick(e) }

  height: 44
  font: 12, 500;
  flexAlign: center;
  padding: 15, 18;
  margin: 20, 10;
  radius: 3;
  clickable: true
  color: white;
  boxSizing: border-box;
  transition: "background 0.1s ease-in-out";
  background: 0x3f51b5;

  if(disabled || pending){
    background: 0xdbdbdb;
    cursor: initial
  }

  if(pending)
    CircularProgress, do {
      size = 20
      marginTop: -3
    }
  else 
    void label || children;
}