import { Overview } from "./overview"

import { RouteContainer } from "components/window";
import { Router } from '@reach/router';
import { SpecificUser } from "./details"
import { AddNew } from "./new"

export const People = () => do {
  Router, do {
    Overview(path `/`);
    AddNew(path `/add`)
    SpecificUser(path `:userID`);
    OhNo({ default: true })
  }
}

const OhNo = () => do {
  RouteContainer, do {
    "o no"
  }
}