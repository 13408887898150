import { useConstant, useRefresh } from "@gabeklein/hooks"
import { LinearProgress } from '@material-ui/core';
import { useMemo, useCallback } from "react";

import { RouteContainer } from "components/window";
import { Header } from "components/window"
import { NiceInput } from "components/input"
import { SwishInOut } from "components/animation"

import { searchPlayStore, addAppToWhitelist, icon120 } from "./lib";

function useAppSearchResults() {
  const state = useConstant();
  const update = useRefresh();

  const runSearch = useCallback(async (query) => {
    if(query !== state.query){
      state.thinking = true;
      update();
      state.results = await searchPlayStore(query);
      state.thinking = false;
      state.query = query;
      update();
    }
  })

  return {
    ...state,
    runSearch,
    key: state.results ? state.results[0].id : ""
  }
}

export const AppSearch = ({ position }) => do {
  const {
    results,
    thinking,
    key,
    runSearch,
    query
  } = useAppSearchResults();

  const apps = useMemo(() => do {
    if(query)
      for(const app of results)
        AppResult(...app,
          onClick = async () => {
            await addAppToWhitelist(position, app)
            history.back();         
          }
        )
  }, [query]);
    overflowY: auto; 
  RouteContainer, do {
    font: 20;

    Header(`Add new app`);

    NiceInput, do {
      placeholder = `Search for an app`
      onReturn = runSearch
    }

    SwishInOut, do {
      currentKey = key

      height: initial;
      flex: initial;
      
      if(results){
        app_results, do {
          minHeight: 300;
          paddingT: 20;
          flexAlign: right;
          flexWrap: wrap;
          transition: "opacity 0.1s ease"

          if(thinking){
            opacity: 0.3
          }

          void apps;
        }
      }

      else {
        margin: 50, 10, 0
        width: 350
        textAlign: center;
        height: 300;
        font: 12;
        color: 0x999;
        
        stuff, do {
          border: 0xe5e5e5;
          radius: 10
          padding: 30, 40

          if(thinking)
            LinearProgress();
          else 
            "Search for app to enable within this group"
        }
      }
    }
  }
}

const AppResult = ({
  id,
  img,
  name,
  publisher,
  onClick
}) => do {

  + onClick;

  bg: 0xe7edf1;
  width: 260
  position: relative;
  flexAlign: center, right;
  padding: 10, 20;
  marginV: 10;
  marginR: 20;
  radius: 8;
  clickable: true
  transition: "transform 0.2s cubic-bezier(.31,.36,.05,1)"

  onHover: {
    transform: "translateX(4px)"

    desc: {
      opacity: "0"
      transition: "opacity 0.05s ease"
    }

    highlight: {
      opacity: 1
    }
  }
  
  !img, do {
    src = icon120(img);
    size: 3.0;
    minWidth: 3.0;
    marginR: 15;
    radius: 14
    backgroundColor: 0x12
  }

  desc, do {
    absolute: fill;
    paddingH: 100, 40;
    textAlign: center;
    transition: "transform 0.3s cubic-bezier(.31,.36,.05,1)"
    flexAlign: center, down;
    flex: 1;

    void name, do {
      marginT: 4
      font: 0.6
    }
    void publisher, do {
      marginT: 6;
      color: 0x777;
      font: 0.6
    }
  }

  body, do {
    flex: 1

    highlight, do {
      transition: "opacity 0.05s cubic-bezier(.31,.36,.05,1)"
      textAlign: center;
      radius: 5
      border: 0xb3e0ad
      opacity: "0"
      paddingV: 10;

      "add to whitelist", do {
        font: 11
        color: 0x888
      }
      void id, do {
        maxWidth: 170
        margin: 2, auto, 0
        font: 12
        overflow: hidden;
      }
    }
  }
};