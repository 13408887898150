import { SwipeAnimatedRoutes } from "components/animation"

import { Header } from './header';
import { SideBar, NavLink } from "./sidebar";
import { PageNotFound, Logout } from "./meta"
import { Settings, Support } from "./wip"
import { Locations } from "./locations"
import { People } from "./users"
import { Policies } from "./profiles"

export const Admin = () => do {
  margin: auto;
  maxWidth: 1280;
  textAlign: center;

  Header;
  SideBar >> 
    Links;

  SwipeAnimatedRoutes, do {
    keyForLocation = loc => loc.pathname;
      
    People, path `people/*`
    Policies, path `position/*`
    Locations, path `locations/*`
    Settings, path `settings`
    Support, path `support`
    Logout, path `logout`

    PageNotFound({ default: true })
  }
}

const Links = () => do {
  header: {
    marginT: 40;
    marginL: 40;
    font: 14, 600;
    color: 0xb0b0b0;
    marginBottom: 20
  }

  header `MANAGE`;
  NavLink(pre `/admin`, to `People`, icon `avatar`)
  NavLink(pre `/admin`, to `Position`, icon `edit`, size = 22)
  NavLink(pre `/admin`, to `Locations`, icon `shop`)

  header `ACCOUNT`;
  // NavLink(pre `/admin`, to `Settings`, icon `settings`, size = 23)
  // NavLink(pre `/admin`, to `Support`, icon `chat`)
  NavLink(pre `/admin`, to `Logout`, icon `logout`, size = 18)
}
