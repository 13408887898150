import firebase from "firebase/app";
import { fromDatabase } from "./firebase";

const { SESSION, LOCAL } = firebase.auth.Auth.Persistence;

/** 
 * Attempt to login with Google.
 * 
 * Generates user account entry (empty, with profile) if doesn't already exist.
 * 
 * @argument rememberMe - user set flag "remember this device"
 * 
 * @returns User entry in database.
 */ 

export async function trySignInWithGoogle(rememberMe){
  let user;

  try {
    const auth = firebase.auth()
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    await auth.setPersistence(rememberMe ? LOCAL : SESSION);
    await auth.signInWithPopup(provider).then(data => {
      user = data.user
    })
  }
  catch(err){
    console.error(err.message);
    throw err;
  }

  const { uid } = user;

  let entry = await fromDatabase(`/account/${uid}`);

  if(!entry)
    throw new Error(
      "Account used is either unregistered or not a manager. Try a different one?")
  
  return { uid, ...entry };
}