import { Router, Location } from "@reach/router";
import { Conveyor } from "use-conveyor";
import { Suspense } from "react";

export const SwishInOut = ({ currentKey, children, className }) => do {
  + className;

  position: relative;
  height: "100%"
  flex: 1;

  Conveyor, do {
    + currentKey;

    absolute: fill;
    transition: "all 0.3s ease-in-out"

    css: incoming: {
      zIndex: 20;
      position: relative
      opacity: "0";
      transform: "translateX(-5px)";
    }

    css: stable: {
      opacity: 1;
      transform: "translateX(0px)";
    }

    css: outgoing: {
      zIndex: 10
      opacity: "0";
      transform: "translateX(20px)"
    }

    void children;
  }
}

export const SwipeAnimatedRoutes = ({ className, children, ...props }) => do {
  AnimateRoutes(...props), do {
    + className;

    transition: "opacity 0.3s ease-in, transform 0.5s cubic-bezier(.31,.36,.05,1)"
    boxSizing: border-box;
    minHeight: 100, vh
    position: relative;
    zIndex: 2;

    css: incoming: {
      opacity: "0.5";
      transform: "translate3d(-6px, 6px, 0) scale(1.02)"
    }

    css: stable: {
      opacity: 1;
      transform: "translateX(0)";
    }

    css: outgoing: {
      absolute: fill;
      zIndex: 1
      opacity: "0";
      transform: "translate3d(-6px, 6px, 0) scale(1.02)"
      transition: none
    }

    void children;
  }
}

export const StackAnimatedRoutes = ({ className, children, ...props }) => do {
  AnimateRoutes(...props), do {
    + className;

    transition: "opacity 0.3s ease-in, transform 0.5s cubic-bezier(.31,.36,.05,1)"
    boxSizing: border-box;
    minHeight: 100, vh
    position: relative;
    zIndex: 2;

    css: incoming: {
      opacity: "0";
      transform: "scale(1.1)";
    }

    css: stable: {
      opacity: 1;
      transform: "scale(1)";
    }

    css: outgoing: {
      absolute: fill;
      zIndex: 1
      opacity: "0";
      transform: "scale(0.95)"
    }

    void children;
  }
}

export const AnimateRoutes = (props) => do {
  const { 
    keyForLocation: key,
    children,
    time = 800,
    ...rest
  } = props;
  
  Location(
    ({ location }) => do {
      const currentKey = key
        ? key(location)
        : location.key;
      
      overflow: hidden;
      position: relative;
      minHeight: "100vh";

      Conveyor(...rest, currentKey, time) >>
        Suspense(fallback = null) >>
          Router(location, children)
    }
  )
}
