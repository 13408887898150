import { navigate } from '@reach/router';
import { RouteContainer } from "components/window"
import { Button } from "components/things"
import { signOut } from 'lib/firebase';

export const PageNotFound = (props) => do {
  div `Page "${ props.uri || "/" }" Not Found`;
  div `Sorry about that 😅`
}

export const Logout = () => do {
  RouteContainer, do {
    flexAlign: center;
    
    container, do {
      border: 0xE3E3E3;
      shadow: 0x06;
      radius: 10
      size: 340, 330;
      flexAlign: center, down;

      prompt, do {
        fontSize: 14;
        color: 0x999;

        void "Are you sure you wish to logout?"
      };

      Button, do {
        onClick = async () => {
          await signOut();
          navigate("/login")
        }

        void `Logout`;

        background: 0x3f51b5;
        radius: 3;
        color: white;
        width: 150;
        margin: 30, auto, 0;
      }
    }
  }
}