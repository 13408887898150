import { useDidMount } from '@gabeklein/hooks';
import { useState } from 'react';
import { checkForUser, fromDatabase, toDatabase, removeFromDatabase } from 'lib/firebase';

export function useLocationData() {
  const [locations, setData] = useState({});

  useDidMount(async () => {
    const user =
      await checkForUser();

    const locations =
      await fromDatabase(`/org/${user.org}/locations`)

    setData(locations);
  })

  return locations;
}

export function useBeaconData(locationId) {
  const [beacons, setData] = useState({});
  useDidMount(async () => {
    const user = await checkForUser();
    const bcns = await fromDatabase('beacons')
    const locationBeacons = Object.entries(bcns).filter(([_, { loc }]) => loc === `${user.org},${locationId}`)
      .reduce((prev, [key, value]) => {
        prev[key] = value
        return prev
      }, {})
    setData(locationBeacons);
  })
  return beacons;
}

export function useLocationDetailData(locationId) {
  const [location, setData] = useState({});

  useDidMount(async () => {
    const user =
      await checkForUser();

    const loc =
      await fromDatabase(`/org/${user.org}/locations/${locationId}`)

    setData(loc);
  })

  return location;
}

export const addBeacon = async (beaconId, locationId) => {
  const user = await checkForUser();
  toDatabase(`beacons/${beaconId}`, { loc: `${user.org},${locationId}` })
}

export const removeBeacon = async (beaconId) => removeFromDatabase(`beacons/${beaconId}`)
