import { useDidMount, useRefresh } from "@gabeklein/hooks";
import { navigate } from "@reach/router";

import { Header, RouteContainer } from "components/window"
import { Icon } from "components/things"
import { query, checkForUser } from "lib/firebase";

import { cache, onHeartbeats } from "./lib";
import { StatusCell } from "components/things"

function useUserStats() {
  const update = useRefresh();

  useDidMount(async () => {
    const user = await checkForUser();

    cache.users = await query("/account", "org", user.org)

    update();
  })

  return cache.users
}

function useHeartbeats() {
  const update = useRefresh();
  useDidMount(async () => {
    const user = await checkForUser();
    cache.heartbeats = await onHeartbeats(user.org, update);
  })
  return cache.heartbeats
}

export const Overview = () => do {
  const users = useUserStats();
  const heartbeats = useHeartbeats();
  const entries = Object.entries(users);

  RouteContainer, do {
    Header`Users`;

    font: 14, 500;
    color: 0x888;

    greyClickable, do {
      width: 170
      paddingH: 30, 40;
      border: 0x07
      marginB: 30;
      marginR: 15
      marginT: 15
      boxSizing: border-box;
      height: 50
      clickable: true
      radius: 13
      bg: 0xf2f5f8
      color: 0x666;
      flexAlign: space-between, right;
      alignItems: center;

      onClick = () => {
        navigate("admin/people/add")
      }

      Icon, do {
        icon = `plus`
        bg: 0x8eabc5;
        marginR: 10
      }

      void `Add User`;
    }

    users, do {
      display: flex;
      flexWrap: wrap;
      maxWidth: 800;

      if (users)
        for (const [key, user] of entries) {
          // const active = heartbeats[key] && Date.now() - heartbeats[key].timestamp < 1000 * 60 * 15
          const active = false;
          UserStat(
            label = user.profile.name,
            reference = key,
            color = active ? "#000000" : "#aaaaaa",
            click = () => {
              navigate(`admin/people/${key}`)
            }
          )
        }
    }
  }
}

const UserStat = ({ label, color, click }) => do {
  const dot = color || undefined;

  onClick = click;

  StatusCell, do {
    minWidth: 240
    paddingH: 30, 40;

    onActive: {
      priority: 10
      transform: "translateY(-3px) scale(1.02)"
    }

    onHover: {
      transform: translateY("-3px")
    }

    color = dot;
    !span.status > " ".concat(label);
  }
}