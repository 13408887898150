import { LogoAnimated as Logo } from "components/logo";
import { ButtonPending } from "components/ui";
import { useInviteProcess } from "./auth";

export const Onboard = () => do {

  const state = useInviteProcess();
  const { current } = state;

  flexAlign: center, down;
  height: 100, vh;
  color: 0x777;

  LogoType();

  if (current == "exists")
    Exists(state)
  else if (current == "error")
    Issue()
  else if (current == "done")
    Done(state)
  else
    Prompt(state)
}

const Done = ({ state }) => do {
  prompt`Welcome to ${state.org}, ${state.firstname}!`, do {
    font: 24, 300;
    marginV: 30, 10;
  };

  sub`You're all set, simply install this and login with your device to complete this process`, do {
    font: 14
    marginT: 10
    width: 300;
    textAlign: center;
  }

  void WSZLink;
}

const Exists = ({ state }) => do {
  flexAlign: center, down;

  prompt`Hi ${state.firstname}!`, do {
    font: 24, 300;
    marginV: 30, 10;
  };

  sub`Seems your account is already set up with us, simply login with your device to complete this process`, do {
    font: 14
    marginT: 10
    width: 300;
    color: 0xb95353;
    textAlign: center;
    lineHeight: 19;
  }

  "Need the app?", do {
    font: 0.8;
    marginV: 30, -16;
  }
  void WSZLink
}

const Issue = () => do {
  prompt`Your invite link is either invalid or expired.`, do {
    marginV: 30, 10;
  };

  sub`Your manager may be able to help you with this by sending a new invite`, do {
    font: 12
  }
}

const Prompt = ({ state }) => do {
  h1, do {
    marginT: 30;
    font: 18;

    span: {
      color: 0x0076a3;
    }

    "You've been added to the " + state.org + " workspace zone!"
  }

  h2, do {
    marginT: 10;
    font: 0.9;
    "Connect your Google account to continue."
  }

  if (state.prompt)
    prompt, do {
      font: 14
      marginT: 30
      width: 300;
      color: 0xb95353;
      textAlign: center;
      lineHeight: 19;

      void state.prompt;
      !br; !br;
      "Try logging in again";
    }

  ButtonPending, do {
    label = "Login with Google"
    pending = state.thinking

    onClick = state.trySignin;
    marginT: 40
    width: 140
  }
}

const WSZLink = do {
  !a, do {
    marginT: 20;

    href = "https://play.google.com/store/apps/details?id=com.futurehax.worksafezone";

    !img, do {
      src = `https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png`
      width: 180
    } 
  }
}

const LogoType = () => do {
  flexAlign: center;

  Logo: {
    size: 40
  }

  txt: {
    font: Roboto, 24, 400
    marginL: 15
    color: 0x0076a3;
  }

  Logo();
  txt`Work Safe Zones`;
}