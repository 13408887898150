import { sleep } from "good-timing";
import { useState, useRef } from "react";
import { useConstant, useDidMount } from "@gabeklein/hooks";

import { NiceInput } from "components/input";
import { Toggle } from "components/interact";
import { RouteContainer, Section, Header } from "components/window";
import { ButtonPending } from "components/ui";
import { ShiftSelection } from "components/shifts";
import { Checkbox } from "components/things";

import { intoDatabase, getUser, fromDatabase } from "lib/firebase"
import { Form, Field } from "react-final-form";

const ENDPOINT = "https://us-central1-work-safe-zone-1518730203544.cloudfunctions.net/v1/";

async function submitInvite(values) {

  const { key } = await intoDatabase(`/account_pending`, values);
  await fetch(ENDPOINT + `invite_user?inviteId=${key}`);

  // const x = ref;
  // const email = encodeURIComponent(x.current.value);
  // setPending(true);
  // await fetch(ENDPOINT + `invite_user?email=${email}&org=${encodeURIComponent(orgSlug)}&orgName=${encodeURIComponent(org.name)}&inviteId=${inviteId}`)
  // await toDatabase(`invited/${inviteId}`, { email, orgSlug, policy })
  // setPending(false);
  // setSent(true);
  // await sleep({ sec: 1 });
  // setSent(false);
  // ref.current.value = "";
}

// function useForAddNew(){
//   const state = useConstant(() => ({
//     isPending: false,
//     isSent: false,
//     ref_email: {},
//     user: getUser()
//   }))
// }

export const AddNew = () => do {
  const [ org, setOrg ] = useState(null);
  const [ isSent, setSent] = useState(false);
  let pos_1;

  delete useDidMount(async () => {
    let user = getUser();
    let retry = 20;
    while(!user && --retry > 0){
      await sleep(200);
      user = getUser();
    }

    const info = await fromDatabase(`/org/${user.org}`);
    info.id = user.org;
    setOrg(info);
  })

  if(org)
  RouteContainer, do {
    Header`Add new user`;

    container, do {
      height: 400
      color: 0x999;

      sub, do {
        font: 14;
        marginB: 10;

        void `Invite new user to ${org.name}`;
      }

      emailBox, do {
        border: 0x1;
        padding: 0, 20, 14;
        radius: 5;

        Form,
          isSent,
          dataPolicies = org.policies,
          initialValues = {
            orgName: org.name,
            orgSlug: org.id,
            position: pos_1 = Object.keys(org.policies)[0],
            shift: Object.keys(org.policies[pos_1].shifts)[0],
            admin: false
          },
          onSubmit = submitInvite, 
          component = InviteFields;
      }
    }
  }
}

const InviteFields = (props) => do {

  const { values, handleSubmit, submitting, isSent, dataPolicies } = props;

  const policy = dataPolicies[values.position];
  const shifts = policy && policy.shifts;
  
  paddingV: 10, 0

  polcy, do {
    margin: 20, 10
    div `Set user policy on signup`, do {
      marginV: 20, 40;
      font: 14
    }

    ShiftSelection(
      dataPolicies,
      values,
      policy,
      shifts
    )
  }
  
  aboutUser, do {
    flexAlign: right;
    alignItems: center;
    marginV: 24, 30;

    Field, do {
      name = "email"
      placeholder = `Email Address`
      render = ({ input, className, placeholder }) => do {
        NiceInput(...input, className, placeholder)
      }
  
      font: 18;
      marginL: 8;
    }

    shouldMakeAdmin, do {
      flexAlign: center, right;
      marginL: 20;
      clickable: true;

      onClick = () => {
        props.form.change("admin", !values.admin)
      }

      Checkbox( checked = values.admin );
      "Set as administrator", do {
        marginL: 10;
        font: 13
      }
    }
  }

  ButtonPending, do {
    pending = submitting
    onClick = handleSubmit
    label = isSent ? "Sent!" : "Send Invite";

    width: 160;
  }
}