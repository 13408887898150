export const capitalize = (word) => 
  typeof word == "string" ? word[0].toUpperCase() + word.slice(1) : "";

export const dashToCapital = (word) => 
  typeof word == "string" ? word.split(/[-_]/g).map(capitalize).join(" ") : "";

export function parseParameters(string){
  let o = {};
  for(const item of string.slice(1).split("&")){
    const [key, value] = item.split("=");
    o[key] = decodeURIComponent(value);
  }
  return o;
}

export const range = (i, max) => {
  if(max === undefined){
    max = i;
    i = 0;
  }

  const array = [];

  while(i <= max){
    array.push(i);
    ++i;
  }
  
  return array;
}