
import { RouteContainer } from "components/window";
import { navigate } from '@reach/router';
import { Icon } from "components/things"
import { ZoneHeader, Header } from "./common";
import { usePolicyData, icon60, addNewPosition } from "./lib";

export const Positions = () => do {

  const policies = usePolicyData();

  RouteContainer, do {
    Header `Positions`;
  
    AddNew(
      onClick = addNewPosition
    )
    
    for(const ref in policies)
      PolicyPreview(
        key=ref, 
        policy=policies[ref]
      )
  }
}

const AddNew = ({ onClick }) => do {

  width: 200
  paddingH: 30, 40;
  border: 0x07
  marginT: 40
  marginR: 15
  marginB: 30;
  marginL: 10;
  boxSizing: border-box;
  height: 50
  clickable: true
  font: 14
  color: 0x888;
  radius: 8
  bg: 0xf2f5f8
  color: 0x666;
  flexAlign: space-between, right;
  alignItems: center;

  + onClick

  Icon, do {
    icon = `plus`
    bg: 0x8eabc5;
  }

  void `New Position`;
}

const PolicyPreview = (props) => do {
  const { id, apps, name } = props.policy;

  onClick = () => {
    navigate("admin/position/" + id)
  }

  margin: 30, 10, 0;
  background: 0xf2f5f8;
  radius: 10;
  paddingV: 30;
  paddingH: 40, 20;
  minWidth: 200;
  cursor: pointer;
  WebkitUserSelect: none;
  transition: "all 0.04s ease-in"

  onHover: {
    background: 0xeff2f5;
    transform: translateX("-4px")

    context: {
      transform: initial;
      opacity: 1
    }
  }

  header, do {
    flexAlign: space-between, right;
    alignItems: center;
    marginB: 10

    ZoneHeader(name)
    MetaButtons()
  }

  content, do {
    display: flex;
    flexDirection: right;
    flexWrap: wrap

    if(apps.length)
      apps, do {
        flexAlign: right;

        for(const app of apps)
          AppIcon(app)
      }

    else {
      void "Nothing whitelisted on this policy", do {
        font: 12, 500;
        color: 0xBBB;
        marginT: 10
        marginL: 15
      }
    }
  }
}

const AppIcon = ({ app }) => do {
  const icon = app.icon ? icon60(app.icon) : "/smartphone.svg";

  marginR: 12
  marginT: 10;
  bg: 0x07;
  border: 0x2;
  overflow: hidden;
  radius: 14;
  padding: 5;

  !img.hi, do {
    src = icon

    if(!app.icon){
      boxSizing: border-box;
      padding: 5;
      opacity: 0.7;
    }

    size: 45;
    radius: 9;
    opacity: 0.7
    display: block
  }
}

const MetaButtons = () => do {
  float: right;
  paddingV: 4;
  paddingH: 30, 10
  font: 14, 500;
  color: 0x999;
  flexAlign: center, right;
  transform: "translateX(10px)"
  opacity: "0"
  transition: "all 0.2s ease"

  button: {
    flexAlign: center;
    size: 30;
    radius: 4
    marginH: 4;
    bg: transparent;
    borderWidth: "1px"
    borderStyle: solid;
    borderColor: transparent;

    icon_img: {
      bg: 0xBBB;
    }
  }

  button.open, do {
    onHover: {
      bg: 0x06;
    }
    icon_img, do {
      size: 16;
      bg: 0xBBB;
      WebkitMaskImage: "url(/dots.svg)";
    }
  }

  button.delete, do {
    onClick = (e) => {
      alert("WIP: cant delete this without checking who's using");
      e.stopPropagation()
    }
    onHover: {
      borderColor: 0xffbfbf;
      icon_img: {
        bg: 0xfa9592
      }
    }
    icon_img, do {
      size: 11;
      bg: 0xBBB;
      WebkitMaskImage: "url(/x.svg)"
    }
  }
} 